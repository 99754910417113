<script setup lang="ts">
import type { AccordionItem } from '#ui/types';
import { isString } from 'lodash-es';
import lodashMergeWith from 'lodash-es/mergeWith';
import { twMerge } from 'tailwind-merge';
import type { PropType } from 'vue';

// PROPS
const { size } = defineProps({
    items: {
        type: Array as PropType<AccordionItem[]>,
        default: undefined,
    },
    defaultOpen: {
        type: Boolean,
    },
    multiple: {
        type: Boolean,
    },
    size: {
        type: String as PropType<'md' | 'lg' | 'xl'>,
        default: 'lg',
    },
});

// COMPOSABLES
const classes = useCssModule();

// DATA
const sizeUi = computed(() => {
    switch (size) {
        case 'md':
            return { default: { class: 'font-normal text-base' } };
        case 'lg':
            return { default: { class: 'font-medium text-base' } };
        case 'xl':
            return { default: { class: 'font-medium md:text-2xl text-xl' }, item: { size: 'text-base' } };
    }

    return {};
});
const mergedUi = computed(() =>
    lodashMergeWith(
        {
            container: classes.accordion,
            item: {
                color: 'text-black dark:text-white',
            },
            default: {
                class: `my-4 w-full`,
                truncate: true,
            },
        },
        sizeUi.value,
        (objValue, srcValue) => {
            if (isString(objValue) && isString(srcValue)) {
                return twMerge(objValue, srcValue);
            }
        }
    )
);
</script>

<template>
    <UAccordion
        :size="size"
        :items="items"
        :default-open="defaultOpen"
        :multiple="multiple"
        :padded="false"
        variant="ghost"
        color="black"
        :ui="mergedUi"
    >
        <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
    </UAccordion>
</template>

<style module>
.accordion {
    @apply border-b border-neutral-200;
}
.accordion:last-child:not(:only-child) {
    @apply border-b-0;
}
</style>
